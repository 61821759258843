import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

// idea to product import
import IdeaBooster from "./components/pages/IdeaBooster/a-idea-booster";

//form import
import BookMeeting from "./components/pages/Form/bookmeeting";

//thank you
import ThankYouPage from "./components/pages/Thank/ThankYou";

import Ganalytics from './Ganalytics';
import Meta from './Meta';


function App() {
  return (
    <BrowserRouter basename="/">
      <Ganalytics />
      <Meta />
      <Routes>
        {/* Cap Digisoft Main Pages (this import will load the page on initial server run) */}
        <Route exact path="/" element={<IdeaBooster />} />

        {/* Solutions Pages Links */}
        <Route path="/IdeaBooster" element={<IdeaBooster />} />

        {/* form route */}
        <Route path="/form" element={<BookMeeting />} />

        {/* thankyou route */}
        <Route path="/thankyou" element={<ThankYouPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
