/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./idea-booster.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import HeaderDark from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";
import LazyLoad from 'react-lazyload';

// icons imports
import star from "../../../../src/app/assets/images/idea-booster/star.png";
import rocket from "../../../../src/app/assets/images/idea-booster/rocket.gif";
import arrow from "../../../../src/app/assets/images/idea-booster/arrow-hero.png";
import trophy from "../../../../src/app/assets/images/idea-booster/trophy.png";
import bullseye from "../../../../src/app/assets/images/idea-booster/bullseye.png";
import thumbsup from "../../../../src/app/assets/images/idea-booster/thumbsup.png";
import think from "../../../../src/app/assets/images/idea-booster/think.png";
import arrowdown from "../../../../src/app/assets/images/idea-booster/arrow-down.gif";
import hand from "../../../../src/app/assets/images/idea-booster/hand.png";
import phone from "../../../../src/app/assets/images/idea-booster/phone.png";

// cutout imports

import cutout1 from "../../../../src/app/assets/images/idea-booster/vision.jpg";
import cutout2 from "../../../../src/app/assets/images/idea-booster/cutout2.jpg";
import cutout3 from "../../../../src/app/assets/images/idea-booster/cutout3.jpg";
import cutout4 from "../../../../src/app/assets/images/idea-booster/cutout4.jpg";
import cutout5 from "../../../../src/app/assets/images/idea-booster/cutout5.png";

// bg gradient balls import
import ball1 from "../../../../src/app/assets/images/idea-booster/vision-ball.png";
import ball2 from "../../../../src/app/assets/images/idea-booster/vision-ball2.png";

// video imports
import testmonial from "../../../../src/app/assets/images/idea-booster/testimonial.mp4";
import testmonial2 from "../../../../src/app/assets/images/idea-booster/testimonial2.mp4";
import testmonial4 from "../../../../src/app/assets/images/idea-booster/testimonial4.mp4";

// video thumbnail images imports
import thumb1 from "../../../../src/app/assets/images/idea-booster/thumb1.jpg";
import thumb2 from "../../../../src/app/assets/images/idea-booster/thumb2.jpg";
import thumb4 from "../../../../src/app/assets/images/idea-booster/thumb4.jpg";

// client testimonials images imports
import test1 from "../../../../src/app/assets/images/idea-booster/client1.png";
import test2 from "../../../../src/app/assets/images/idea-booster/client2.png";
import test3 from "../../../../src/app/assets/images/idea-booster/client3.png";

// sr icons imports
import sr1 from "../../../../src/app/assets/images/idea-booster/sr1.png";
import sr2 from "../../../../src/app/assets/images/idea-booster/sr2.png";
import sr3 from "../../../../src/app/assets/images/idea-booster/sr3.png";
import sr4 from "../../../../src/app/assets/images/idea-booster/sr4.png";

// mini icons imports
import ic1 from "../../../../src/app/assets/images/idea-booster/icons/1.png";
import ic2 from "../../../../src/app/assets/images/idea-booster/icons/2.png";
import ic3 from "../../../../src/app/assets/images/idea-booster/icons/3.png";
import ic4 from "../../../../src/app/assets/images/idea-booster/icons/4.png";
import ic5 from "../../../../src/app/assets/images/idea-booster/icons/5.png";
import ic6 from "../../../../src/app/assets/images/idea-booster/icons/6.png";
import ic7 from "../../../../src/app/assets/images/idea-booster/icons/7.png";
import ic8 from "../../../../src/app/assets/images/idea-booster/icons/8.png";
import ic9 from "../../../../src/app/assets/images/idea-booster/icons/9.png";
import ic10 from "../../../../src/app/assets/images/idea-booster/icons/10.png";
import ic11 from "../../../../src/app/assets/images/idea-booster/icons/11.png";
import ic12 from "../../../../src/app/assets/images/idea-booster/icons/12.png";
import ic13 from "../../../../src/app/assets/images/idea-booster/icons/13.png";

// product icons imports
import product1 from "../../../../src/app/assets/images/idea-booster/fura.png";
import product2 from "../../../../src/app/assets/images/idea-booster/clued-in.png";
import product3 from "../../../../src/app/assets/images/idea-booster/iledger.png";
import product4 from "../../../../src/app/assets/images/idea-booster/intellicue.png";
import product5 from "../../../../src/app/assets/images/idea-booster/hire-harmony.png";

// cds product logo imports
import slide1 from "../../../../src/app/assets/images/idea-booster/products/iledger.webp";
import slide2 from "../../../../src/app/assets/images/idea-booster/products/Intellicue.webp";
import slide3 from "../../../../src/app/assets/images/idea-booster/products/J&P.webp";
import slide4 from "../../../../src/app/assets/images/idea-booster/products/Clude.in.webp";
import slide5 from "../../../../src/app/assets/images/idea-booster/products/mockup.webp";
import slide6 from "../../../../src/app/assets/images/idea-booster/products/social.webp";
import slide7 from "../../../../src/app/assets/images/idea-booster/products/bussopi.webp";
import slide8 from "../../../../src/app/assets/images/idea-booster/products/attesta.webp";

// marquee images imports
import mc1 from "../../../../src/app/assets/images/idea-booster/marquee-media/m1.png";
import mc2 from "../../../../src/app/assets/images/idea-booster/marquee-media/m2.png";
import mc3 from "../../../../src/app/assets/images/idea-booster/marquee-media/m3.png";
import mc4 from "../../../../src/app/assets/images/idea-booster/marquee-media/m4.png";
import mc5 from "../../../../src/app/assets/images/idea-booster/marquee-media/m5.png";
import mc6 from "../../../../src/app/assets/images/idea-booster/marquee-media/m6.png";
import mc7 from "../../../../src/app/assets/images/idea-booster/marquee-media/m7.png";
import mc8 from "../../../../src/app/assets/images/idea-booster/marquee-media/m8.png";
import mc9 from "../../../../src/app/assets/images/idea-booster/marquee-media/m9.png";
import mc10 from "../../../../src/app/assets/images/idea-booster/marquee-media/m10.png";
import mc11 from "../../../../src/app/assets/images/idea-booster/marquee-media/m11.png";

const IdeaBooster = () => {
const properties = {
prevArrow: (
<button className="cs-btn ib-btn-left">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
  </svg>
</button>
),
nextArrow: (
<button className="cs-btn btn-right ib-btn-right">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
  </svg>
</button>
),
};

const properties2 = {
prevArrow: (
<button className="cs-btn2 ib-btn-left">
  <i class="fas fa-arrow-left"></i>
</button>
),
nextArrow: (
<button className="cs-btn2 btn-right ib-btn-right">
  <i class="fas fa-arrow-right"></i>
</button>
),
};
const responsiveSettings = [
{
breakpoint: 991,
settings: {
slidesToShow: 3,
slidesToScroll: 1,
},
},
{
breakpoint: 575,
settings: {
slidesToShow: 2,
slidesToScroll: 1,
},
},
{
breakpoint: 320,
settings: {
slidesToShow: 1,
slidesToScroll: 1,
},
},
];
return (
<>
  <div className="idea-booster-page">
    <HeaderDark />

    {/* Hero Section Start Here */}
    <div className="wg-banner-1 border-line-btm">
      <div className="themesflat-container">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <div className="content text-center position-relative">
              <div className="star-icon">
                <LazyLoad height={50} offset={100}>
                  <img src={star} className="star-1" alt="ib-img" loading="lazy" />
                </LazyLoad>
                <LazyLoad height={50} offset={100}>
                  <img src={star} className="star-2" alt="ib-img" loading="lazy" />
                </LazyLoad>
              </div>
              <div className="title hero-title">
                <h2>
                  Unleash the Power of Your App with the Idea Booster
                 <span>
                    <img src={rocket} className="rocket-gif" alt="ib-img" loading="lazy" />
                 </span>
                  Program
                </h2>
              </div>
              <p className="hero-para">
                We empower you to showcase the potential of your app idea to
                the world by meticulously designing it and strategically
                positioning its value.
              </p>

              <div className="position-relative">
                <a href="#" className="tf-button get-start h45 mx-auto btn-glow" data-bs-toggle="modal"
                  data-bs-target="#meeting">
                  <span>READY TO START?</span>
                </a>
                <LazyLoad height={50} offset={100}>
                  <img src={arrow} className="arrow-hero" alt="ib-img" loading="lazy" />
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Section End Here */}

    {/* Innovative Realization Section Start Here */}
    <div class="wg-result cust-padding-2 bg-black overflow-hidden border-line-btm">
      <div class="themesflat-container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="heading-section content text-center wow fadeInUp">
              <div class="sub-title text-gradient">
                Innovative Realization
              </div>
              <div class="main-title title">
                <h2 class="mb-0">
                  Transform Your <br />
                  Vision{" "}
                  <img src={bullseye} class="ms-0 rocket-gif" alt="ib-img" />{" "}
                  into Reality
                </h2>
              </div>
            </div>
          </div>

          <div class="col-lg-7">
            <p class="mb-4">
              Those with experience in app development advise starting
              small. This means validating your idea before heavily
              investing in the full development process.{" "}
              <b>
                CAP Digisoft's Idea Booster program enables cost-effective,
                insightful validation by designing your app screen by screen
                for user feedback without a large initial cost.
              </b>{" "}
              This approach can draw co-founders, investors, and users,
              facilitating a smoother market entry.
            </p>

            <h3 class="head-gradient my-4">
              This isn't just about aesthetics;
              <br /> It's about experience.
            </h3>

            <p className="
                mb-5">
              Leveraging extensive experience in successful mobile app
              design, CAP Digisoft collaborates closely to{" "}
              <b>ensure your product is usable, marketable & scalable </b>{" "}
              incorporating cost efficiency, technology consideration, and
              optimal user experience into our design philosophy.
            </p>
          </div>

          <div class="col-lg-5 mt-lg-0 mt-md-5">
            <div class="text-lg-end text-center position-relative d-none d-sm-block">
              <img src={cutout1} class="img-fluid vision-img" alt="ib-img" />

              <div class="vision-bg">
                <img src={ball1} class="vison-1" alt="ib-img" />
                <img src={ball2} class="vison-2" alt="ib-img" />
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="text-center mx-auto mt-3">
              <div class="d-flex flex-column align-items-center">
                <div class="free-con">
                  <img src={arrowdown} alt="ib-img" />
                </div>

                <a href="#" class="tf-button get-start h45 mx-auto btn-glow" data-bs-toggle="modal"
                  data-bs-target="#meeting">
                  <span>Let's Begin Your Journey Together</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Section End Here */}

    {/* Unlocking the Path Section Start Here */}
    <div class="wg-create-design v-testi cust-padding border-line-btm">
      <div class="themesflat-container">
        <div class="row justify-content-center offer-sec pt-0">
          <div class="content text-center">
            <div class="heading-section wow fadeInUp style-2">
              <div class="sub-title text-gradient gradient-secondary">
                Unlocking the Path
              </div>
              <div class="main-title title">
                <h2 class="mb-0 section-head">
                  What Idea Booster{" "}
                  <img src={rocket} class="rocket-gif" alt="ib-img" />{" "}
                  Offers
                </h2>
              </div>
            </div>
            <p class="text-dark vdo-sec-txt cust-head-para">
              Idea Booster provides a complete array of services to elevate
              your app concept from initial ideation to a proven and
              compelling idea that attracts the necessary resources for
              development and launch.
            </p>
          </div>

          <div class="col-lg-4 col-md-6 card-margin">
            <div class="card h-100 offer-card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <h3 class="card-numb">1</h3>
                  </div>
                  <h4 class="mb-3 ms-3 fw-bold">Idea Refinement</h4>
                </div>
                <p class="text-dark">
                  {" "}
                  We refine your app idea to meet market needs and appeal to
                  your target audience, establishing a solid development
                  foundation.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 card-margin">
            <div class="card h-100 offer-card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <h3 class="card-numb">2</h3>
                  </div>
                  <h4 class="mb-3 ms-3 fw-bold">Technical Feasibility</h4>
                </div>
                <p class="text-dark">
                  Our experts evaluate the technical feasibility of your
                  app, ensuring a smooth development process without feature
                  compromises.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 card-margin">
            <div class="card h-100 offer-card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <h3 class="card-numb">3</h3>
                  </div>
                  <h4 class="mb-3 ms-3 fw-bold">App Design</h4>
                </div>
                <p class="text-dark">
                  We create UX/UI designs with wireframes, mockups, and
                  prototypes to optimize user experience and gather feedback
                  before development.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 card-margin">
            <div class="card h-100 offer-card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <h3 class="card-numb">4</h3>
                  </div>
                  <h4 class="mb-3 ms-3 fw-bold">
                    Development Cost Analysis
                  </h4>
                </div>
                <p class="text-dark">
                  {" "}
                  We offer a detailed financial analysis for development,
                  maintenance, and updates, aiding in budgeting and funding
                  efforts.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 card-margin">
            <div class="card h-100 offer-card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <h3 class="card-numb">5</h3>
                  </div>
                  <h4 class="mb-3 ms-3 fw-bold">Pitch Deck Service</h4>
                </div>
                <p class="text-dark">
                  Our pitch deck showcases your app's market potential,
                  value, and team to attract investors and partners
                  effectively.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Section End Here */}

    {/* Expert Guidance Section Start Here */}
    <div class="wg-result bg-black cust-padding overflow-hidden position-relative border-line-btm">
      <div class="themesflat-container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="heading-section content wow fadeInUp text-center">
              <div class="sub-title text-gradient">
                Expert Guidance Every Step of the Way
              </div>
              <div class="main-title title">
                <h2 class="mb-0 p-0">
                  Your journey from concept to product is in capable hands{" "}
                  <img src={thumbsup} class="rocket-gif" alt="ib-img" />
                </h2>
              </div>
            </div>

            <div class="position-relative expt-sec">
              <img src={ball1} class="guide-1" alt="ib-img" />
              <img src={ball2} class="guide-2" alt="ib-img" />
            </div>

            <div class="d-flex flex-wrap justify-content-center tetx-center expertise-card">
              <div>
                <img src={ic1} alt="ib-img" />
                Product Success Strategy
              </div>
              <div>
                <img src={ic2} alt="ib-img" />
                Product Design
              </div>
              <div>
                <img src={ic3} alt="ib-img" />
                Product Fundraising
              </div>
              <div>
                <img src={ic4} alt="ib-img" />
                Product Development
              </div>
              <div>
                <img src={ic5} alt="ib-img" />
                Product UI/UX
              </div>
              <div>
                <img src={ic6} alt="ib-img" />
                Product Pricing Strategy
              </div>
              <div>
                <img src={ic7} alt="ib-img" />
                Product Technology Discovery
              </div>
              <div>
                <img src={ic8} alt="ib-img" />
                Product Incremental Development
              </div>
              <div>
                <img src={ic9} alt="ib-img" />
                Product Go To Market Strategy
              </div>
              <div>
                <img src={ic10} alt="ib-img" />
                Product Marketing
              </div>
              <div>
                <img src={ic11} alt="ib-img" />
                Product Sales
              </div>
              <div>
                <img src={ic12} alt="ib-img" />
                Product Customer Support
              </div>
              <div>
                <img src={ic13} alt="ib-img" />
                Product IP Discovery
              </div>
            </div>
          </div>

          <div class="col-12 pb-3">
            <div class="text-center mx-auto mt-3">
              <div class="d-flex flex-column align-items-center">
                <div class="free-con">
                  <img src={arrowdown} alt="ib-img" />
                </div>

                <a href="#" class="tf-button get-start h45 mx-auto btn-glow" data-bs-toggle="modal"
                  data-bs-target="#meeting">
                  <span>
                    Take the First Step – Click to Transform Your App Dream!
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Section End Here */}

    {/* Vision to Victory Section Start Here */}
    <div class="wg-result cust-padding bg-black border-line-btm">
      <div class="themesflat-container">
        <div class="row justify-content-center">
          <div class="content text-center">
            <div class="heading-section wow fadeInUp style-2">
              <div class="sub-title text-gradient">Vision to Victory</div>
              <div class="main-title title">
                <h2 class="mb-0 section-head">
                  We recently transformed these app ideas into reality
                  <img src={trophy} class="rocket-gif" alt="ib-img" />
                </h2>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="card h-100 vic-card border-card-1">
              <div class="card-body text-md-center">
                <img src={product1} alt="ib-img" />
                <p class="mt-4">
                  Fura is an innovative AI-powered specialty pharmacy
                  prescription tool that stands at the forefront of this
                  revolution, redefining the way healthcare providers manage
                  and dispense specialty medications.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 position-relative">
            <div class="card h-100 vic-card border-card-2">
              <div class="card-body text-md-center">
                <img src={product2} alt="ib-img" />
                <p class="mt-4">
                  Clued.in Closet offers an efficient closet management and
                  outfit planning. Seamlessly scheduling outfits and giving
                  daily suggestions tailored to local weather, thanks to
                  advanced AI.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="card h-100 vic-card border-card-3">
              <div class="card-body text-md-center">
                <img src={product3} alt="ib-img" />
                <p class="mt-4">
                  A comprehensive truck fault inspection is a proactive
                  approach to fleet maintenance, contributing to the safety
                  and efficiency of commercial vehicles.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center top-grad-border">
          <div class="col-lg-4 col-md-6">
            <div class="card h-100 vic-card border-card-4 pb-0">
              <div class="card-body text-md-center pb-0">
                <img src={product4} alt="ib-img" />
                <p class="mt-4">
                  Intellicue is an innovative online learning platform
                  designed to support individuals in enhancing their mental
                  health through a variety of engaging tools.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 position-relative">
            <div class="card h-100 vic-card border-card-5 pb-0">
              <div class="card-body text-md-center pb-0">
                <img src={product5} alt="ib-img" />
                <p class="mt-4">
                  HireHarmony is a web application designed to streamline
                  and optimize the hiring processes of organizations,
                  leveraging intuitive features and advanced technologies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Section End Here */}

    {/* Your App Journey Section Start Here */}
    <div class="wg-result bg-black cust-padding border-line-btm">
      <div class="themesflat-container">
        <div class="row justify-content-center">
          <div class="content text-center">
            <div class="heading-section wow fadeInUp style-2">
              <div class="sub-title text-gradient">
                We Bring to Your App Journey
              </div>
              <div class="main-title title">
                <h2 class="mb-0 section-head">
                  What{" "}
                  <img src={think} class="rocket-gif ms-0" alt="ib-img" />
                  Value Do We Add?
                </h2>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="cust-width">
              <ul class="value-sec-list">
                <li>
                  <b class="text-gradient">
                    Refinement and Cost-Efficiency:
                  </b>{" "}
                  Use Idea Booster to sharpen your project's focus and
                  ensure efficient use of your budget.
                </li>
                <li>
                  <b class="text-gradient">
                    Early User Feedback Integration:
                  </b>{" "}
                  Avoid costly development mistakes by validating features
                  with real user insights from the start.
                </li>
                <li>
                  <b class="text-gradient">Focus on What Matters:</b> Our
                  method ensures your project concentrates on
                  functionalities that truly resonate with your audience.
                </li>
                <li>
                  <b class="text-gradient">
                    Deep Dive into User Requirements:
                  </b>{" "}
                  Uncover new opportunities and gain a deep market
                  understanding to align with user needs and expectations.
                </li>
                <li>
                  <b class="text-gradient">Leverage Proven Success:</b>{" "}
                  Benefit from our experience in launching successful
                  products, utilizing insights on captivating
                  functionalities, user confidence strategies, and optimal
                  technologies.
                </li>
                <li>
                  <b class="text-gradient">Innovate with Confidence:</b>{" "}
                  Save significantly while pushing the boundaries of
                  innovation, ensuring your offering deeply connects with
                  target users and distinguishes itself in a competitive
                  market.
                </li>
                <li class="mb-0">
                  <b class="text-gradient">
                    Strategic, User-Centered Development:{" "}
                  </b>
                  Achieve a development process that's not just financially
                  prudent but also positions you as a leader in innovation
                  and market relevance.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Section End Here */}

    {/* Our Innovations Section Start Here */}
    <div class="wg-result bg-black cust-padding border-line-btm">
      <div class="themesflat-container">
        <div class="row justify-content-center">
          <div class="content text-center">
            <div class="heading-section wow fadeInUp style-2">
              <div class="main-title title">
                <h2 class="mb-0 section-head">
                  Our <span class="custom-underline">Innovations</span>
                  <br />
                  By Our Experts
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="carousel_se_01 bg-black">
        <div class="container cust-slider-cont mb-5">
          <Slide className="brand-slider" {...properties} slidesToScroll={1} slidesToShow={2} indicators={false}
            duration={700} infinite={true} pauseOnHover={false} autoplay={true} responsive={responsiveSettings}>
            <div class="card">
              <img src={slide1} alt="Logo" class="img-fluid mb-1" />
              <div className="product-details">
                <h3 class=" fw-bold">Iledgers (B2B)</h3>
                <p>
                  Features customizable checklists, photo capture, real-time
                  reporting, and fleet management to improve inspection
                  efficiency.
                </p>
              </div>
            </div>
            <div class="card">
              <img src={slide2} alt="Logo" class="img-fluid mb-1" />
              <div className="product-details">
                <h3 class=" fw-bold">Intellicue (B2B)</h3>
                <p>
                  An education app is a digital tool that enhances learning
                  through interactive lessons, quizzes, and personalized
                  content.
                </p>
              </div>
            </div>
            <div class="card">
              <img src={slide3} alt="Logo" class="img-fluid mb-1" />
              <div className="product-details">
                <h3 class=" fw-bold">J&P Hospitality (B2B)</h3>
                <p>
                  Enhance guest experiences with exceptional service,
                  efficient operations, staff training, resource
                  optimization, and engagement.
                </p>
              </div>
            </div>
            <div class="card">
              <img src={slide4} alt="Logo" class="img-fluid mb-1" />
              <div className="product-details">
                <h3 class=" fw-bold">Clued.in (B2B)</h3>
                <p>
                  Closet: the ultimate app to organize clothes, plan
                  outfits, and enhance your personal style.
                </p>
              </div>
            </div>
            <div class="card">
              <img src={slide5} alt="Logo" class="img-fluid mb-1" />
              <div className="product-details">
                <h3 class=" fw-bold">Carfidante (B2B)</h3>
                <p>
                  Streamline car inspections with custom checklists,
                  real-time reporting, photo capture, and fleet management
                  integration.
                </p>
              </div>
            </div>
            <div class="card">
              <img src={slide6} alt="Logo" class="img-fluid mb-1" />
              <div className="product-details">
                <h3 class=" fw-bold">Social lite (B2B)</h3>
                <p>
                  Make new friends, join events, and find communities. Safe,
                  simple, and personal. Start connecting today!
                </p>
              </div>
            </div>
            <div class="card">
              <img src={slide7} alt="Logo" class="img-fluid mb-1" />
              <div className="product-details">
                <h3 class=" fw-bold">Bussopi (B2B)</h3>
                <p>
                  Bussopi is a reliable app for managing and validating
                  personal information, including legal documents, health
                  data, and personality behavior.
                </p>
              </div>
            </div>
            <div class="card">
              <img src={slide8} alt="Logo" class="img-fluid mb-1" />
              <div className="product-details">
                <h3 class=" fw-bold">Attesta AI (Product)</h3>
                <p>
                  A document reader is a software application for displaying
                  and managing digital documents in various formats like PDF
                  and DOCX.
                </p>
              </div>
            </div>
          </Slide>
        </div>
      </section>
    </div>
    {/* Section End Here */}

    {/* Leverage Our Expertise Section Start Here */}
    <div class="wg-result cust-padding bg-black overflow-hidden border-line-btm">
      <div class="themesflat-container">
        <div class="row justify-content-center align-items-center">
          <div class="col-xxl-7 col-md-7 order-lg-1 order-2">
            <div class="sub-section">
              <h3 class="text-white mb-4">
                Leverage Our Expertise and Credibility
              </h3>
              <p>
                As your technical partner, CAP Digisoft's reputation becomes
                a powerful tool in attracting further investment. Our
                credibility in the tech industry acts as a testament to the
                potential of your app, providing investors with the
                confidence they need to commit to your project.
              </p>
            </div>
          </div>

          <div class="col-xxl-5 col-md-5 order-lg-2 order-1">
            <div class="text-center position-relative">
              <img src={cutout3} class="sub-sec-img img-fluid mb-4" alt="ib-img" />

              <div class="grad-ball d-lg-block d-none">
                <img src={ball1} class="grad-ball-1 img-fluid" alt="ib-img" />
                <img src={ball2} class="grad-ball-2 img-fluid" alt="ib-img" />
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center align-items-center mt-5">
          <div class="col-xxl-5 col-md-5">
            <div class="text-center position-relative">
              <img src={cutout4} class="sub-sec-img img-fluid mb-4" alt="ib-img" />
              <div class="grad-ball d-lg-block d-none">
                <img src={ball1} class="grad-ball-1" alt="ib-img" />
                <img src={ball2} class="grad-ball-2" alt="ib-img" />
              </div>
            </div>
          </div>

          <div class="col-xxl-7 col-md-7">
            <div class="sub-section">
              <h3 class="text-white mb-4">
                From Start to Launch: A Partnership for Success
              </h3>
              <p>
                Our commitment goes beyond just getting started; we are with
                you every step of the way, from the spark of an idea to the
                excitement of the launch and beyond. CAP Digisoft isn't just
                about building apps; we're about building successes. Join us
                on this journey to turn your app into the next market
                sensation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Section End Here */}

    {/* App Journey with Idea Booster! Section Start Here */}
    <div class="wg-result cust-padding  bg-black overflow-hidden border-line-btm">
      <div class="themesflat-container">
        <div class="row justify-content-center align-items-center">
          <div class="col-12">
            <div class="content text-center">
              <div class="heading-section wow fadeInUp style-2">
                <div class="sub-title text-gradient">
                  App Journey with Idea Booster!
                </div>
                <div class="main-title title">
                  <h2 class="mb-0 section-head px-3">
                    Empower Your App's Journey: From Concept to Launch with
                    Idea Booster
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div>
              <p class="mb-5">
                Unlock the full potential of your app idea with Idea
                Booster. Prove market demand, attract vital investments, and
                leverage CAP Digisoft's comprehensive suite of services for
                complete app development, strategic market entry, launch
                support, and beyond. With us, gain a technical partner whose
                expertise and credibility opens doors to investors, ensuring
                a seamless path from inception to market success.
              </p>

              <h5 class="text-gradient">
                Comprehensive Support with Idea Booster
              </h5>
              <p class="mb-5">
                At the heart of every successful app is an idea validated by
                market demand and backed by strategic investment. Idea
                Booster by CAP Digisoft is your first step towards turning
                your vision into a viable, market-ready product. Our initial
                phase focuses on validating your app's market demand, a
                critical step in attracting the investment needed to bring
                your idea to life.
              </p>

              <h5 class="text-gradient">
                Beyond Validation: Your Journey to Launch
              </h5>
              <p>
                Validation is just the beginning. With CAP Digisoft, you
                transition seamlessly from concept validation to full-scale
                development. Our end-to-end services cover.
              </p>
            </div>
          </div>

          <div class="col-lg-6 position-relative">
            <div class="text-center">
              <img src={cutout2} class="mob-img img-fluid" alt="ib-img" />
            </div>

            <div class="blur-ball">
              <img src={ball1} class="ball-1" alt="ib-img" />
              <img src={ball2} class="ball-2" alt="ib-img" />
            </div>
          </div>

          <div class="col-12 cust-spacing">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-5">
                  <img src={sr1} alt="ib-img" />
                  <h5 class="text-white my-3 fw-bold">
                    Complete App Development
                  </h5>
                  <p>
                    Leveraging the latest technologies and design
                    principles, we transform your validated idea into a
                    fully functional app.
                  </p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-5">
                  <img src={sr2} alt="ib-img" />
                  <h5 class="text-white my-3 fw-bold">
                    Go-To-Market Strategy
                  </h5>
                  <p>
                    Navigate the complexities of market entry with a
                    tailor-made strategy, ensuring your app reaches its
                    target audience effectively.
                  </p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-5">
                  <img src={sr3} alt="ib-img" />
                  <h5 class="text-white my-3 fw-bold">Launch Support</h5>
                  <p>
                    Benefit from our expertise in launching apps
                    successfully, making your debut in the market smooth and
                    impactful.
                  </p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="">
                  <img src={sr4} alt="ib-img" />
                  <h5 class="text-white my-3 fw-bold">
                    Ongoing Customer Support
                  </h5>
                  <p>
                    We provide continuous support to ensure your app remains
                    competitive, addressing user feedback and implementing
                    necessary updates.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Section End Here */}

    {/* Dream into an App Section Start Here */}
    <div class="wg-result cust-padding-2 bg-black border-line-btm">
      <div class="themesflat-container">
        <div class="row justify-content-center">
          <div class="content text-center">
            <div class="heading-section wow fadeInUp style-2">
              <div class="sub-title text-gradient">
                Turn Your Dream into an App
              </div>
              <div class="main-title title">
                <h2 class="mb-0 section-head">
                  Ready{" "}
                  <img src={hand} class="rocket-gif ms-0" alt="ib-img " />
                  to Start?
                </h2>
              </div>
            </div>
          </div>

          <div class="gallery-sec-para ">
            <p class="text-center mb-5">
              Taking the first step towards realizing your app development
              dream is easier
              <br class="d-md-block d-none" />
              than you think. With CAP Digisoft's Idea Booster program,
              you're gaining a
              <br class="d-md-block d-none" />
              partner dedicated to transforming your idea into reality.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center mx-auto expertise-cta mt-0">
            <p>
              Like only 8% of the found stake action on their idea,{" "}
              <br class="d-md-block d-none" />
              be part of that 8% and schedule a FREE consulting today
            </p>

            <div class="d-flex flex-column align-items-center mt-3">
              <div class="free-con">
                <img src={arrowdown} alt="ib-img" />
              </div>

              <a href="#" class="tf-button get-start h45 mx-auto btn-glow" data-bs-toggle="modal"
                data-bs-target="#meeting">
                <span>Schedule a no-obligation consultation today</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Section End Here */}

    {/* Navigating Your App's Journey Section Start Here */}
    <div class="wg-result cust-padding bg-black border-line-btm">
      <div class="themesflat-container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="sub-section">
              <h5 class="gallery-sub-head mb-0">
                Navigating Your App's Journey
              </h5>
              <h3 class="text-white mb-4">
                Let's Begin Your Journey Together
              </h3>
              <p>
                At CAP Digisoft, we're not just offering our expertise;
                we're ready to invest our time to ensure your app idea
                flourishes. We believe that the foundation of a successful
                project starts with open communication, which is why talking
                to us costs nothing.{" "}
              </p>
              <br />
              <p>
                Our team is a global assembly of{" "}
                <b>
                  top talents from India, Poland, Central America, and the
                  USA, bringing a wealth of diverse skills and perspectives
                  to your project.
                </b>{" "}
                We challenge the notion that access to such high-caliber
                professionals should be exclusive to Fortune 100 companies.
                With CAP Digisoft, this level of expertise and dedication is
                now available to you, ready to guide your app from concept
                to market success.
              </p>
              <br />
              <p>
                We encourage you to schedule a no-obligation consultation
                today and discover how our commitment can transform your
                vision into reality.
              </p>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mx-auto text-center mt-5">
              <img className="img-fluid" src={cutout5} alt="ib-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Section End Here */}

    {/* Testimonials Section Start Here */}
    <div class="wg-result cust-padding-2 bg-black border-line-btm">
      <div class="themesflat-container">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="content">
              <div class="heading-section wow fadeInUp style-2">
                <div class="sub-title text-gradient">
                  Reliability, Expertise, & Exceptional Results
                </div>
                <div class="main-title title text-start">
                  <h2 class="mb-0 section-head p-0">Testimonials</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="banner-content position-relative">
          <Slide {...properties2} autoplay={false} duration={3000}>
            <div className="each-slide-effect">
              <div class="card text-center testi-card-custom h-100 ">
                <div class="card-body">
                  <div class="testimonial-wrapper">
                    <div class="testimonial-content">
                      <p class="testi-para">
                        “CAP Digisoft has been exceptional in bringing my AI
                        software idea to life through their top-notch
                        creativity and extensive experience. From initial
                        discussions to development, their valuable guidance
                        made the process seamless. Their expertise ensured
                        ease in navigating steps, enhancing the idea, and
                        making it a reality. I highly recommend CAP Digisoft
                        for any future tech or digital projects.”
                      </p>

                      <div class="rating-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                      </div>

                      <div class="d-flex align-items-center">
                        <div class="testi-image">
                          <img src={test1} alt="ib-img" />
                        </div>

                        <div class="">
                          <h6 class="text-white">Omar Mora</h6>
                          <p class="m-0 job-role">BUSSOPI</p>
                        </div>
                      </div>
                    </div>
                    <div class="video-container" id="video-container">
                      <button type="button" class="btn btn-primary rounded-circle player-button" data-bs-toggle="modal"
                        data-bs-target="#FeedbackOne">
                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="currentColor"
                          class="bi bi-play-fill" viewBox="0 0 16 16">
                          <path
                            d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393" />
                        </svg>
                      </button>
                      <img className="img-fluid custom-thumbnail" src={thumb2} alt="Thumbnail" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="each-slide-effect">
              <div class="card text-center testi-card-custom">
                <div class="card-body">
                  <div class="testimonial-wrapper">
                    <div class="testimonial-content">
                      <p class="testi-para">
                        INZYNCH, as a disruptive company in team and
                        organization management, needed to migrate from
                        proprietary Excel tools to a scalable platform.
                        After evaluating eight options, CAP Digisoft stood
                        out with their quick and high-quality response,
                        including a demo that exceeded expectations. This
                        proactive approach not only aligned with our needs
                        but also helped generate excitement among our
                        current clients. We look forward to a long-term
                        partnership with CAP Digisoft.”
                      </p>
                      <div class="rating-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                      </div>

                      <div class="d-flex align-items-center">
                        <div class="testi-image">
                          <img src={test2} alt="ib-img" />
                        </div>

                        <div class="">
                          <h6 class="text-white">Alberto Garcia</h6>
                          <p class="m-0 job-role">INZYNCH</p>
                        </div>
                      </div>
                    </div>
                    <div class="video-container" id="video-container">
                      <button type="button" class="btn btn-primary rounded-circle player-button" data-bs-toggle="modal"
                        data-bs-target="#FeedbackTwo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="currentColor"
                          class="bi bi-play-fill" viewBox="0 0 16 16">
                          <path
                            d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393" />
                        </svg>
                      </button>
                      <img className="img-fluid custom-thumbnail" src={thumb1} alt="Thumbnail" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="each-slide-effect">
              <div class="card text-center testi-card-custom h-100 ">
                <div class="card-body">
                  <div class="testimonial-wrapper">
                    <div class="testimonial-content">
                      <p class="testi-para">
                        "Working with offshore teams led by senior
                        executives like Arun has been a common experience
                        for me over the last 25 years. What sets this team
                        apart is their balance of guidance and freedom,
                        resulting in impressive creativity and quality work.
                        I'll keep CAP Digisoft in my contacts for future
                        projects and referrals, and I look forward to
                        celebrating our successes together someday."
                      </p>
                      <div class="rating-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-star-fill text-yellow" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                      </div>

                      <div class="d-flex align-items-center">
                        <div class="testi-image">
                          <img src={test3} alt="ib-img" />
                        </div>

                        <div class="">
                          <h6 class="text-white">Kelvin Yu</h6>
                          <p class="m-0 job-role">BUSSOPI</p>
                        </div>
                      </div>
                    </div>
                    <div class="video-container" id="video-container">
                      <button type="button" class="btn btn-primary rounded-circle player-button" data-bs-toggle="modal"
                        data-bs-target="#FeedbackThree">
                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="currentColor"
                          class="bi bi-play-fill" viewBox="0 0 16 16">
                          <path
                            d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393" />
                        </svg>
                      </button>
                      <img className="img-fluid custom-thumbnail" src={thumb4} alt="Thumbnail" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
          {/* testimonials video modals */}
          <div className="testimonials-video_bucket">
            <div class="modal fade" id="FeedbackOne" tabindex="" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body">
                    <video className="testimonial-video rounded" loop="true" controls="true" autoplay="true"
                      muted="false">
                      <source src={testmonial2} type="video/mp4" />
                    </video>
                  </div>
                  {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button> */}
                </div>
              </div>
            </div>
            <div class="modal fade" id="FeedbackTwo" tabindex="" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body">
                    <video className="testimonial-video rounded" loop="true" controls="true" autoplay="true"
                      muted="false">
                      <source src={testmonial} type="video/mp4" />
                    </video>
                  </div>
                  {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button> */}
                </div>
              </div>
            </div>
            <div class="modal fade" id="FeedbackThree" tabindex="" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body">
                    <video className="testimonial-video rounded" loop="true" controls="true" autoplay="true"
                      muted="false">
                      <source src={testmonial4} type="video/mp4" />
                    </video>
                  </div>
                  {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center mx-auto mt-3">
          <div class="d-flex flex-column align-items-center">
            <div class="free-con">
              <img src={arrowdown} alt="ib-img" />
            </div>

            <a href="#" class="tf-button get-start h45 mx-auto btn-glow" data-bs-toggle="modal"
              data-bs-target="#meeting">
              <span>Click Here to Kickstart Your Dream App!</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    {/* Section End Here */}

    {/* Apps We've Worked with Section Start Here */}
    <div class="wg-result cust-padding bg-black border-line-btm">
      <div class="content text-center">
        <div class="heading-section wow fadeInUp style-2">
          <div class="main-title title">
            <h2 class="mb-0 section-head">Apps We've Worked with</h2>
          </div>
        </div>
      </div>

      <article class="wrapper">
        <div class="marquee mb-4">
          <div class="marquee__group">
            <div class="language-item">
              <img src={mc1} alt="ib-img" />
              <div class="title">
                <a href="#">Fura</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc2} alt="ib-img" />
              <div class="title">
                <a href="#">Jugl</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc3} alt="ib-img" />
              <div class="title">
                <a href="#">Attesta</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc4} alt="ib-img" />
              <div class="title">
                <a href="#">Clued.in Closet</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc5} alt="ib-img" />
              <div class="title">
                <a href="#">iLedgers</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc6} alt="ib-img" />
              <div class="title">
                <a href="#">aiya</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc7} alt="ib-img" />
              <div class="title">
                <a href="#">Carfidante</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc8} alt="ib-img" />
              <div class="title">
                <a href="#">Hire Harmony</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc9} alt="ib-img" />
              <div class="title">
                <a href="#">Intellicue</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc10} alt="ib-img" />
              <div class="title">
                <a href="#">J&amp;P</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc11} alt="ib-img" />
              <div class="title">
                <a href="#">Bussopi</a>
              </div>
            </div>
          </div>

          <div aria-hidden="true" class="marquee__group">
            <div class="language-item">
              <img src={mc1} alt="ib-img" />
              <div class="title">
                <a href="#">Fura</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc2} alt="ib-img" />
              <div class="title">
                <a href="#">Jugl</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc3} alt="ib-img" />
              <div class="title">
                <a href="#">Attesta</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc4} alt="ib-img" />
              <div class="title">
                <a href="#">Clued.in Closet</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc5} alt="ib-img" />
              <div class="title">
                <a href="#">iLedgers</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc6} alt="ib-img" />
              <div class="title">
                <a href="#">aiya</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc7} alt="ib-img" />
              <div class="title">
                <a href="#">Carfidante</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc8} alt="ib-img" />
              <div class="title">
                <a href="#">Hire Harmony</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc9} alt="ib-img" />
              <div class="title">
                <a href="#">Intellicue</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc10} alt="ib-img" />
              <div class="title">
                <a href="#">J&amp;P</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc11} alt="ib-img" />
              <div class="title">
                <a href="#">Bussopi</a>
              </div>
            </div>
          </div>
        </div>

        <div class="marquee marquee--reverse">
          <div class="marquee__group">
            <div class="language-item">
              <img src={mc1} alt="ib-img" />
              <div class="title">
                <a href="#">Fura</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc2} alt="ib-img" />
              <div class="title">
                <a href="#">Jugl</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc3} alt="ib-img" />
              <div class="title">
                <a href="#">Attesta</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc4} alt="ib-img" />
              <div class="title">
                <a href="#">Clued.in Closet</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc5} alt="ib-img" />
              <div class="title">
                <a href="#">iLedgers</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc6} alt="ib-img" />
              <div class="title">
                <a href="#">aiya</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc7} alt="ib-img" />
              <div class="title">
                <a href="#">Carfidante</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc8} alt="ib-img" />
              <div class="title">
                <a href="#">Hire Harmony</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc9} alt="ib-img" />
              <div class="title">
                <a href="#">Intellicue</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc10} alt="ib-img" />
              <div class="title">
                <a href="#">J&amp;P</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc11} alt="ib-img" />
              <div class="title">
                <a href="#">Bussopi</a>
              </div>
            </div>
          </div>

          <div aria-hidden="true" class="marquee__group">
            <div class="language-item">
              <img src={mc1} alt="ib-img" />
              <div class="title">
                <a href="#">Fura</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc2} alt="ib-img" />
              <div class="title">
                <a href="#">Jugl</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc3} alt="ib-img" />
              <div class="title">
                <a href="#">Attesta</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc4} alt="ib-img" />
              <div class="title">
                <a href="#">Clued.in Closet</a>
              </div>
            </div>
            <div class="language-item">
              <img src={mc5} alt="ib-img" />
              <div class="title">
                <a href="#">iLedgers</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc6} alt="ib-img" />
              <div class="title">
                <a href="#">aiya</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc7} alt="ib-img" />
              <div class="title">
                <a href="#">Carfidante</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc8} alt="ib-img" />
              <div class="title">
                <a href="#">Hire Harmony</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc9} alt="ib-img" />
              <div class="title">
                <a href="#">Intellicue</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc10} alt="ib-img" />
              <div class="title">
                <a href="#">J&amp;P</a>
              </div>
            </div>

            <div class="language-item">
              <img src={mc11} alt="ib-img" />
              <div class="title">
                <a href="#">Bussopi</a>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
    {/* Section End Here */}

    {/* Confidently realize app ideas Section Start Here */}
    <div class="wg-result cust-padding-2 bg-black border-line-btm">
      <div class="themesflat-container">
        <div class="row justify-content-center">
          <div class="content text-center">
            <div class="heading-section wow fadeInUp style-2">
              <div class="sub-title text-gradient mb-3">
                Confidently realize app ideas
              </div>
              <div class="main-title title">
                <h2 class="mb-0 section-head">
                  Bringing Your App{" "}
                  <img src={phone} class="rocket-gif" alt="ib-img ms-0" />{" "}
                  Ideas to Life with Confidence
                </h2>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="cust-width">
              <p class="mb-5 text-center">
                Addressing the daunting statistic that only 0.5% of apps
                succeed, CAP Digisoft’s Idea Booster and Idea to Product,
                are designed to navigate through the common pitfalls of app
                development such as lack of product-market fit, inadequate
                marketing strategy, and weak business infrastructure. Idea
                Booster fine-tunes your app idea with targeted user
                feedback, ensuring it meets market demands and stands out,
                while Idea to Product transforms these validated concepts
                into market-ready apps, integrating development, marketing,
                and strategic business planning.
              </p>
              <p class="mb-0 text-center">
                Our programs are bolstered by CAP Digisoft's ISO 27001 and
                HIPAA certifications, emphasizing our commitment to quality
                and data security in bringing your app to life. Through a
                combination of expert guidance, strategic development, and
                comprehensive market analysis, we aim to elevate your app
                into the successful 0.5%, ensuring it is not only developed
                with precision but also positioned for market success and
                growth.
              </p>
            </div>

            <div class="text-center mx-auto mt-3">
              <div class="d-flex flex-column align-items-center">
                <div class="free-con">
                  <img src={arrowdown} alt="ib-img" />
                </div>

                <a href="#" class="tf-button get-start h45 mx-auto btn-glow" data-bs-toggle="modal"
                  data-bs-target="#meeting">
                  <span>
                    Start Building with Clarity and Affordability – Click to
                    Begin
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Section End Here */}

    {/* Footer Section Start Here */}
    <FooterOne />
    {/* Section End Here */}
  </div>
  {/* Page End Here */}

</>
);
};

export default IdeaBooster;