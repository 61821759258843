/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import LogoLight from "../../app/assets/images/logo-light.png";
import BookMeeting from "../../components/pages/Form/bookmeeting";
import "../../components/pages/IdeaBooster/idea-booster.css";

const HeaderOne = () => {
return (
<>
  <header id="header_main" class="header header-fixed navbar-sticky style-absolute border-line-btm">
    <div class="header-inner">
      <div id="site-logo">
        <div id="site-logo-inner">
          <a href="https://capdigisoft.com/" rel="home" class="main-logo">
            <img id="logo_header" class="logo-main" src={LogoLight} alt="ib-img" />
          </a>
        </div>
      </div>

      <div class="header-right">
        <a href="" class="tf-button get-start h45" data-bs-toggle="modal" data-bs-target="#meeting">
          BOOK A MEETING
        </a>
      </div>
    </div>
  </header>

  <div className="modal fade" id="meeting" tabindex="-1" aria-labelledby="apply-modal" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Book a meeting</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <BookMeeting />
        </div>
      </div>
    </div>
  </div>
</>
);
};

export default HeaderOne;